const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {
  brand: "Spectra",
  user: "Unknown",
  year,
  AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
  showCustomizer: false, // Boolean: true, false. Customizer will be opened (visible) first time app was loaded if set to true
  color: {
    primary: "#00BCD4",
    success: "#8BC34A",
    info: "#66BB6A",
    infoAlt: "#7E57C2",
    warning: "#FFCA28",
    danger: "#F44336",
    text: "#3D4051",
    gray: "#EDF0F1"
  },
  settings: {
    layoutBoxed: false, // true, false
    navCollapsed: true, // true, false
    navBehind: false, // true, false
    fixedHeader: true, // true, false
    sidebarWidth: "middle", // small, middle, large
    colorOption: "16", // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: "light" // light, gray, dark
  },
  searchConfig: {
    theme: {
      palette: {
        primary: {
          main: "#303f9f",
        },
        secondary: {
          main: "#4a94df",
        },
      }
    },
    bulkDownload: {
      isEnabled: true,
     
      columnConfig: [
        {
          key: "technical_key",
          displayName: "Tech Key",
          sequence: 1,
          type: "string",
        },
      ],
    },
    searchList: {
      title: "Home",
      uniqueId: "key",
      html: '',
      css: '',
      solrConfig: {
        appId: "CLS",
        queryId: "12",
        query: "*",
      }
    },
    searchDetails: {
      html: '',
      css: ''
    }
  }
};

export default APPCONFIG;
