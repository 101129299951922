import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import settings from './settings';
import tableViewReducer from './tableViewReducer';
import filterReducer from './filterReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  dataTable: tableViewReducer,
  filters: filterReducer,
  settings
})

export default createRootReducer
