import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Route, Redirect } from "react-router-dom";
import loadable from "react-loadable";
import LoadingComponent from "components/Loading";

import {
  userRefresh
} from '../actions/userAuthentication';
import auth from '../actions/auth';
// = styles =
// 3rd
import "styles/bootstrap/bootstrap.scss";
// custom
import "styles/layout.scss";
import "styles/theme.scss";
import "styles/ui.scss";

import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";
import grayTheme from "./themes/grayTheme";



let MainApp = loadable({
  loader: () => import("components/Layout/AppLayout/MainApp"),
  loading: LoadingComponent
});
let Account = loadable({
  loader: () => import("routes/user/"),
  loading: LoadingComponent
});


const getRefreshToken = () => {
  const jwtRefresh = localStorage.getItem('userrefreshtoken');
  localStorage.removeItem('usertoken');
  localStorage.removeItem('userrefreshtoken')
  return userRefresh(jwtRefresh).then((res) => {
    if (res.accessToken) {
      localStorage.setItem('usertoken', res.accessToken);
      localStorage.setItem('userrefreshtoken', res.refreshToken);
    }
  })
};


export class App extends Component {
  componentWillUpdate() { }

  render() {
    // const expires_in = parseInt(localStorage.getItem('expiryTime')); --> we will get 1800000
    const timeout = 1800000- 60000;
    setTimeout(() => {
      const isAuthenticated = auth.isAuthentication();
      if (isAuthenticated) {
        getRefreshToken();
      }
    }, timeout);

    const {
      match,
      location,
      layoutBoxed,
      navCollapsed,
      navBehind,
      fixedHeader,
      sidebarWidth,
      theme
    } = this.props;
    let materialUITheme;
    switch (theme) {
      case "gray":
        materialUITheme = grayTheme;
        break;
      case "dark":
        materialUITheme = darkTheme;
        break;
      default:
        materialUITheme = lightTheme;
    }

    const isRoot = location.pathname === "/" ? true : false;
    if (isRoot) {
      return <Redirect to={"/user/login"} />;
    }

    return (
      <MuiThemeProvider theme={materialUITheme}>
        <div id="app-inner">
          <div className="preloaderbar hide">
            <span className="bar" />
          </div>
          <div
            className={classnames("app-main h-100", {
              "fixed-header": fixedHeader,
              "nav-collapsed": navCollapsed,
              "nav-behind": navBehind,
              "layout-boxed": layoutBoxed,
              "theme-gray": theme === "gray",
              "theme-dark": theme === "dark",
              "sidebar-sm": sidebarWidth === "small",
              "sidebar-lg": sidebarWidth === "large"
            })}
          >
            <Route path={`${match.url}app`} component={MainApp} />
            <Route path={`${match.url}user`} component={Account} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme
});

export default connect(mapStateToProps)(App);
