import { API_RESULTSET_URL } from './apiBaseUrl';

export const dataTableConfig = {
  templateType: "primary",
  enableRowSelect: true,
  showNoOfColumns: 10,
  enableCheckBoxSelection: false,
  /* navigateButton: {
    show: true,
    header: "Redirect",
    icon: '',
    color: ''
  }, */
  toolBar: {
    show: true,
    title: {
      show: false,
      name: "Entitlement",
      style: {}
    },
    search: {
      show: false,
      highlightKeyword: true,
      label: "Search",
      width: "120%",
      style: {
        marginLeft: 0
      }
    },
    columnVisibility: {
      show: true,
      style: {}
    },
    filter: {
      show: true,
      style: {}
    },
    customButtons: [{
      name: "Compare Plan",
      icon: "playlist_add",
      position: "left"
    }],
    style: {}
  },
  header: {
    show: true,
    config: [],
    field: "fieldName",
    /* sort: true,
    columnFilter: true,
    style: {}*/
    /* moreOptions: {
      header: "Actions",
      menuItems: [
        "Add",
        "Remove",
        "Clone"
      ]
    }, */
    width: 130,
    columnsStyle: [{
      "Material Name": {
        style: {
          width: "150px",
          align: "left"
        }
      }
    }],
    columnFilterMenus: [
      {"key": "contain","value": "Contains"},
      {"key": "start with","value": "Starts With"},
      {"key": "ends with","value": "Ends With"},
      {"key": "matches","value": "Matches"},
      {"key": "less than","value": "Less Than"}
    ],
    order: 'asc',
    orderBy: 'material'
  },
  body: {
    content: [],
     cellClicks: [{
      cellName: 'Material Name',
      enableHyperLink: true,
      url: "http://...."
    },
      {
        cellName: 'id'
      }
    ], 
    emptyRows: {
        message: "No Record Found",
        showEmptyRows: true,
        style: {
            fontWeight: 'bolder',
            textAlign: 'center',
            padding: '20px'
        }
    },
    pageNumber: 0,
    pageSize: 10,
    style: {}
  },
  footer: {
      show: true,
      paginations: {
          show: true,
          rowsPerPageOptions: [10, 25, 50],
          colSpan: 6,
          style: {}
      },
      style: {}
  },
  style: {},
  mode: {
    "apiConfig": {
      "apiLink": API_RESULTSET_URL+"/returnData",
      "params": {
        "resultsetId": "resultsetId",
        "appId": "appId",
        "search": "search",
        "pageNumber": "pageNumber",
        "sortColumn": "sortColumn",
        "sortOrder": "sortOrder",
        "sortOrder": "sortOrder",
        "pageSize": "pageSize",
        "pageOffset": "pageOffset",
        "filters": "filters"
      }
    },
    requiredInfo: {
      "resultsetId": 5,
      "appId": "ENT"
    }
  }
};

//dataTableConfig.mode.apiConfig.apiLink = ;

