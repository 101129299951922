/* istanbul ignore file */
import axios from "axios";
import fetchIntercept from 'fetch-intercept';


/* Axios Interceptor*/
export const NetworkService = {
  setupInterceptors: () => {
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        console.log("in axios interceptor");

        const jwt = localStorage.getItem("usertoken");
        const jwtRefresh = localStorage.getItem("userrefreshtoken");
        if (jwt) {
          config.headers.Authorization = jwt;
        } else {
          // if (jwtRefresh) {
          //   config.headers.tokenRefresh = jwtRefresh;
          // }
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        throw error;
      }
    );
  }
};




/* Fetch Interceptor*/
export const unregister = fetchIntercept.register({
  request: function (url, config) {
    console.log("in fetch interceptor");

    const jwt = localStorage.getItem('usertoken');
    const jwtRefresh = localStorage.getItem('userrefreshtoken');
    if (jwt) {
      config.headers.Authorization = jwt;
    }

    return [url, config];
  },

  requestError: function (error) {
    return Promise.reject(error);
  },

  response: function (response) {
    return response;
  },

  responseError: function (error) {
    return Promise.reject(error);
  }
});
