import { API_AUTHENTICATION_URL ,API_RESULTSET_URL} from "../constants/apiBaseUrl";
import { AjaxService } from "../utilities/AjaxService";

export const userLogin = _queryParam => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/signin",
    JSON.stringify(_queryParam)
  ).then(
    res => {
      return res.data;
    },
    error => {
      return error.response.data;
    }
  );
};

export const userRefresh = _queryParam => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/signin/refresh",
    {},
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      refreshToken: _queryParam
    }
  ).then(
    res => {
      return res.data;
    },
    error => {
      return error.response ? error.response.data: null;
    }
  );
};

export const userLogout = _queryParam => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/signout",
    JSON.stringify(_queryParam)
  ).then(
    res => {
      return res.data;
    },
    error => {
      return error.response.data;
    }
  );
};

/** adding user group-id */
export const saveRecord = (queryParam, url) => {
  return  AjaxService.post(
        API_RESULTSET_URL + "/saveRecord",
        JSON.stringify(queryParam)
      ).then(
        res => {
          return res.data;
        },
        error => {
          return error.response.data;
        }
      );
}

/** get  userId from users_group_id*/


export const returnData = (_queryParam) => {
  return  AjaxService.post(
        API_RESULTSET_URL + "/returnData",
        JSON.stringify(_queryParam)
      ).then(
        res => {
          return res.data;
        },
        error => {
          return error.response.data;
        }
      );
}

/**load group_id's */

export const loadFilter = (queryParam) => {
  return AjaxService.post(
    API_RESULTSET_URL + "/loadFilter",
    JSON.stringify(queryParam)
  ).then(res => {
    return res.data;
  },
    (error) => {
      return error.response.data;
    }
  );
}